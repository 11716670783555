(function () {
    'use strict';

    angular.module('adminApp').factory('OrganizationService', OrganizationService);

    OrganizationService.$inject = ['aerosAdminApi'];

    function OrganizationService(aerosAdminApi) {

        var service = {
            vendorList: vendorList,
            getNameByVendorRef: getNameByVendorRef,
            vendorPromise: undefined
        };

        function vendorList(force) {

            if (!service.vendorPromise || force) {
                service.vendorPromise = aerosAdminApi.vendorApi.list();
            }

            return service.vendorPromise;

        }

        function getNameByVendorRef(refName, vendors) {

            var name = "";

            angular.forEach(vendors, function (value) {
                if (value.id === refName) {
                    name = value.name;
                }
            });

            return name;
        }

        return service;
    }
})();